import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const tableOption1 = ()=> {
    return {
        border: true,
        stripe: true,
        highlightCurrentRow	: true,
        align: 'center',
        editBtn: false,
        delBtn: false,
        addBtn: false,
        viewBtn: false,
        refreshBtn: false,
        columnBtn: false,
        height: 'auto',
        menu: false,
    menuWidth: 140,
    column: [{
        width:100,
        label: t('currencySet.name1'),
        prop: 'tenantName'
    }, {
        width:100,
        label: t('currencySet.name2'),
        prop: 'appName'
    }, 
    {
        width:100,
        label: t('currencySet.text10'),
        prop: 'chain',
    },
    {
        width:100,
        label: t('currencySet.text11'),
        prop: 'protocol',
    },
    {
        width:100,
        label: t('currencySet.text1'),
        prop: 'coin',
    },{
        width:200,
        label: t('otherWallat.name1'),
        prop: 'walletName'
    },{
        width:150,
        label: t('otherWallat.text6'),
        prop: 'walletAddress',
    },{
        width:100,
        label: t('otherWallat.name3'),
        prop: 'amount',
    },{
        width:150,
        label: t('currencySet.name5'),
        prop: 'collectionWaterline'
    },{
        width:150,
        label: t('currencySet.name6'),
        prop: 'hotWalletToplimit',
    },{
        width:170,
        label: t('currencySet.name7'),
        prop: 'hotWalletWarnLimit',
    },{
        width:150,
        label: t('otherWallat.name2'),
        prop: 'priority',
    },
    {
        width:170,
        label: t('currencySet.text13'),
        prop: 'createTime',
    }, 
    {
        width:170,
        label: t('otherWallat.name4'),
        prop: 'updateTime',
    },{
        width:100,
        label: t('currencySet.text12'),
        prop: 'status',
        fixed:'right',
    },{
        width: 140,
        label: t("button.operation"),
        prop: 'caozuo',
        fixed:'right',
      }]
        
    }
}
export const coldWallet = ()=> {
    return {
    border: true,
    stripe: true,
    menuAlign: 'center',
    align: 'center',
    editBtn: false,
    delBtn: false,
    addBtn: false,
    viewBtn: false,
    refreshBtn: false,
    columnBtn: false,
    height: 'auto',
    menuWidth: 140,
    menu: false,
    column: [{
        width:100,
        label: t('currencySet.name1'),
        prop: 'tenantName'
    }, {
        width:100,
        label: t('currencySet.name2'),
        prop: 'appName'
    }, 
    {
        width:100,
        label: t('currencySet.text10'),
        prop: 'chain',
    },
    {
        width:100,
        label: t('currencySet.text11'),
        prop: 'protocol',
    },
    {
        width:100,
        label: t('currencySet.text1'),
        prop: 'coin',
    },{
        width:120,
        label: t('otherWallat.name1'),
        prop: 'walletName'
    },{
        width:150,
        label: t('otherWallat.text6'),
        prop: 'walletAddress',
    },{
        width:100,
        label: t('otherWallat.name3'),
        prop: 'amount',
    },{
        width:150,
        label: t('currencySet.name9'),
        prop: 'coldWalletToplimit'
    },{
        width:150,
        label: t('otherWallat.name2'),
        prop: 'priority',
    },
    {
        width:170,
        label: t('currencySet.text13'),
        prop: 'createTime',
    }, 
    {
        width:170,
        label: t('otherWallat.name4'),
        prop: 'updateTime',
    },{
        width:100,
        label: t('currencySet.text12'),
        prop: 'status',
        fixed:'right',
    },{
        width: 140,
        label: t("button.operation"),
        prop: 'caozuo',
        fixed:'right',
      }]
        
    }
}